import * as React from "react";

const FontAwesome: React.FC<{
    ariaHidden?: boolean,
    ariaRole?: string,
    border?: boolean, // Whether or not to show a border radius
    className?: string, // An extra set of CSS classes to add to the component
    fixedWidth?: boolean, // Make buttons fixed width
    flip?: "horizontal" | "vertical", // Flip the icon's orientation.
    inverse?: boolean, // Inverse the icon's color
    name?: string, // Name of the icon to use
    pulse?: boolean, // Rotate icon with 8 steps (rather than smoothly)
    rotate?: 90 | 180 | 270, // The degrees to rotate the icon by
    size?: "lg" | "2x" | "3x" | "4x" | "5x", // The icon scaling size
    spin?: boolean, // Spin the icon
    stack?: "1x" | "2x", // Stack an icon on top of another
    prefix?: string,
    [restProp: string]: any,
}> = ({
          ariaHidden = true,
          border,
          className,
          fixedWidth,
          flip,
          inverse,
          name,
          pulse,
          rotate,
          size,
          spin,
          stack,
          prefix,
          ...restProps
      }) => {

    // this component accepts either the prefix prop, or name prop in the form of "fas fa-user".
    // either way, a prefix MUST be included for it to work correctly

    const classNames = [name];

    border && classNames.push("fa-border");
    fixedWidth && classNames.push("fa-fw");
    flip && classNames.push("fa-flip-" + flip);
    inverse && classNames.push("fa-inverse");
    pulse && classNames.push("fa-pulse");
    rotate && classNames.push("fa-rotate-" + rotate);
    size && classNames.push("fa-" + size);
    spin && classNames.push("fa-spin");
    stack && classNames.push("fa-stack-" + stack);
    prefix && classNames.push(prefix);

    // Add any custom class names at the end.
    className && classNames.push(className);

    return (
        <span
            {...restProps}
            aria-hidden={ariaHidden}
            className={classNames.join(" ")}
        />
    );
}

export default FontAwesome;
