import { Component } from "react";
import PropTypes from "prop-types";
import {observer} from 'mobx-react';
import {
    isExternalLink, isInternalNextLink,
    isMissingProtocol,
} from "../../utils/SchoolBlocksUtilities";
import {StoreContext} from "../../stores/StoreLoader";

import Link from "next/link";
import {withLocaleInURI} from "../../internationalization/i18nURI";

@observer
class ClickableLink extends Component {

    static contextType = StoreContext;

    static propTypes = {
        // can be null.  If it's null, then we pass through the children - an object is just passed onto next/link
        href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        as: PropTypes.string,
        title: PropTypes.string,
        nofollow: PropTypes.bool,
        shallow: PropTypes.bool,
        prependLocale: PropTypes.bool,
    };

    static defaultProps = {
        nofollow: true,
        scroll: true,
        replace: false,
        shallow: false,
    };

    render() {
        // This trick essentially removes properties that shouldn't be DOM attributes and creates a new object with only
        // the valid DOM attributes (like href and title)
        const {nofollow, children, scroll, replace, as, shallow, prependLocale = true, ...validDomAttributes} = this.props;
        let {href} = this.props;
        const {interfaceStore, i18nStore} = this.context;

        const noUrl = href === undefined || href === null || href === "";
        let wrappedComponent;

        if (noUrl) {
            // no URL...smart pass through
            let childrenArr = children || []; // if it's null, make it a null array
            childrenArr = Array.isArray(childrenArr) ? childrenArr : [childrenArr]; // if it's not an array, make it an array

            switch (childrenArr.length) {
                case 0:
                    wrappedComponent = null;
                    break;
                case 1:
                    wrappedComponent = <a {...validDomAttributes} href="#">{childrenArr[0]}</a>;
                    break;
                default:
                    wrappedComponent = <span {...validDomAttributes}>{childrenArr}</span>;
            }
        } else if (isInternalNextLink(href, interfaceStore.currentFullUrl)) {
            // internal link
            // place the {...this.props} at the end so that it overrides the default bundleData and reuseDom attributes

            // Generally speaking, we want to prepend the locale to all internal links. However, navigation links could be
            // "internal links" to different hostnames, in which case they wouldn't catch this case. Only the NavigationLink
            // component knows if it's an internal link, so we provide the option to not prepend a locale.
            href = prependLocale ? withLocaleInURI(href, i18nStore.currentOrgSupportedLocale) : href;

            wrappedComponent = (
                <Link
                    href={href}
                    as={as || href}
                    scroll={scroll}
                    replace={replace}
                    shallow={shallow}
                    prefetch={false}
                    passHref
                    legacyBehavior
                >
                    <a {...validDomAttributes} href={href}>
                        {children}
                    </a>
                </Link>
            );
        } else {
            if (isMissingProtocol(href)) {
                // this should look like an absolute URL, but it doesn't.  Example: "www.url.com"
                href = "http://" + href;
            }

            if (isExternalLink(href, interfaceStore.currentFullUrl)) {
                // any absolute URLs that aren't the current hostname, should be an "external link" and open in a new
                // tab.
                validDomAttributes.target = "_blank";
                validDomAttributes.rel = `${nofollow ? "nofollow" : ""} noopener noreferrer`;
            }

            wrappedComponent = (
                <a {...validDomAttributes} href={href}>
                    {children}
                </a>
            );
        }

        return wrappedComponent;
    }
}

export default ClickableLink
